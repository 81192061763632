// Vars from Webpack config
$breakpoints: (xsmall: 1px, small: 480px, medium: 960px, large: 1280px, xlarge: 1600px);

@import '../../../node_modules/@bair/styles/src/core/functions';
@import '../../../node_modules/@bair/styles/src/core/variables';
@import '../../../node_modules/@bair/styles/src/core/placeholders';
@import '../../../node_modules/@bair/styles/src/core/mixins';
@import '../../../node_modules/@bair/styles/src/vendor/mq';




























































.content {
  margin-bottom: 8rem;
}

.hero {
  background: no-repeat 50% 50% url('../../assets/banners/banner_pilots_hands-640x427.jpg');
  background-size: cover;

  @include mq($from: small) {
    background-image: url('../../assets/banners/banner_pilots_hands-1280x853.jpg');
  }
  @include mq($from: large) {
    background-image: url('../../assets/banners/banner_pilots_hands-1920x315.jpg');
  }
}

.media {
  display: flex;
  margin-top: 5rem;
  max-height: 32rem;

  iframe,
  video {
    background-color: map_get($grayscale, 'darker');
    display: flex;
    flex: 1 0 60%
  }

  aside {
    background-color: map_get($grayscale, 'ultralight');
    flex: 1 1 40%;
    margin-left: 3rem;
    padding: 4rem;

    h2 {
      font-size: 2.4rem;
      margin: 0 0 1.8rem;
    }

    .btn {
      margin-top: 4rem;
    }
  }

  @include mq($until: medium) {
    flex-wrap: wrap;
    flex-direction: column;
    max-height: unset;

    iframe,
    video,
    aside {
      flex: 1 0 100%;
      height: auto;
      width: 100%;
    }

    aside {
      margin: 4rem 0 0;
    }
  }
}
